
.bg-hiw{
   background-color: rgb(10, 21, 35);
}

.swipe{
   display: none;
   color: white;
}

.items{
    flex-wrap: wrap; 
    justify-content: space-evenly;
    display: flex;
    padding: 2rem;
    background-color: rgb(10, 21, 35);
}
.container-hiw{
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../../assets/C2F9441C-267A-4259-8DC5-8FE9BD14FD75.JPG);
    /* background-color: rgb(191, 190, 190); */
    width: 25rem;
    border-radius: 5px;
     
 }
 

 .text1-hiw{
    top: 50%;
    padding: 2rem;
    text-align: center;
    color: rgb(255, 255, 255);
    font-family: "Poppins", serif;
    font-size: 3.5rem; /* Adjust the font size */
    font-weight: 400; /* Adjust the font weight */
  }
 
 .img-hiw{
    height: 100%;
    padding: 1rem;
    width: 15rem;
    border-radius:50%;
 }
 
 @media (max-width: 786px) {
     
      .img-hiw{
        height: 100%;
        width: 100%;
     }
     .container-hiw{
        flex: 1 0 100%;
        scroll-snap-align: center;
        margin-left: 2rem; 
     }
     .items{
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }
    .swipe{
      display: block;
   }
    
 }