@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    box-sizing: border-box;
    margin: 0;
  }
  .parallax {
    overflow: hidden;
    white-space: wrap;
    word-break: break-word;
  }

.parallax {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-color: rgb(255, 255, 255);
    color: azure;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.7rem;
    display: flex; /* Ensure flex layout for proper alignment */
    flex-wrap: wrap;
}

.right-p {
    flex-grow: 1; /* Use flex to fill available space */
    background-image: url('../../../public/ubcospecial.webp.jpeg');
    background-size: cover;
    background-position: 60%; /* Adjust background position */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
    /* filter: blur(50px); Add blur effect to the image */
}

.left-p {
    flex-grow: 1;
    content: ''; /* Create a pseudo-element to act as the overlay layer */
    position: absolute; /* Position the overlay layer absolutely */
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.846); /* Semi-transparent black color */
    z-index: 1; /* Ensure the overlay layer is above the image */
}


    .text-p { 
        width: 80%;
        position: absolute; 
        left: 10%; 
        top: 25%; 
        z-index: +1; 
    } 

.right-p .content-p{
    position: absolute; 
        top: 55%; 
        right: 2%;
        color: #fff;
        font-family: "Poppins";
        font-size: 1.3rem;  
        font-weight: 300; 
        flex-direction: column;
        border-radius: 10% 30% 50% 70%;
        background-color: #05161a;
        box-shadow: inset;
        width: 40%;
    }

    
   
@media (max-width: 786px) {
    .parallax {
        flex-direction: column; /* Change to column layout for smaller screens */
    }
    .left-p{
        left: 0%;
        width: 100%;
    }
    
}


