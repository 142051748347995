.container-whatis{
   display: flex;
    
}

.img-whatis{
    width: 20rem;
}
.img-whatis2{
    padding: 0 10rem 0 10rem;
    width:100%;
    border-radius: 50%;
}

@media (max-width: 786px) {
    .container-whatis{
        flex-wrap: wrap;
     }
     .img-whatis{
        width: 100%;
    }
    .img-whatis2{
        padding: 0;
        width:100%;
        border-radius: 0;
    }
}