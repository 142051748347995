.contact {
    padding: 2rem;
    height: 100%;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 50px;
    color:rgb(19, 90, 94);
  }
  
  .head-c{

    justify-content: center;
  }
  
  .contact .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .contact .textContainer h1 {
    font-size: 100px;
    line-height: 88px;
  }
  
  .contact .textContainer span {
    font-weight: 300;
  }
  
  .contact .formContainer {
    flex: 1;
    position: relative;
  }
  
  .contact .formContainer .phoneSvg {
    stroke: orange;
    position: absolute;
    margin: auto;
    z-index: -1;
  }
  
  .contact .formContainer form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1; /* Νέα προσθήκη */
  }
  
  .contact .formContainer form input,
  .contact .formContainer form textarea {
    padding: 20px;
    background-color: transparent;
    border: 1px solid rgb(19, 90, 94);
    color: rgb(19, 90, 94);
    border-radius: 5px;
  }
  
  .contact .formContainer form button {
    padding: 20px;
    border: none;
    background-color: orange;
    cursor: pointer;
    font-weight: 500;
  }
  
  .contact .formContainer form button:hover {
    background-color: rgba(0, 0, 0, 0.097);
  }
  .lil-imgs{
    display: flex;
    justify-content: start;

 }
 .img-c{
  width: 7rem;
  padding: 0.2rem;
  border-radius: 20px;
 }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .contact {
      width: 100%;
      padding:10px;
      flex-direction: column;
    }
  
    .contact .textContainer {
      gap: 20px;
      text-align: center;
      align-items: center;
      margin-top: 50px;
    }
  
    .contact .textContainer h1 {
      font-size: 30px;
    }
  
    .contact .formContainer {
      padding: 2rem;
      width: 100%;
    }
  
    .contact .formContainer form input,
    .contact .formContainer form textarea,
    .contact .formContainer form button {
      padding: 10px;
    }
    svg{
      width: 250px;
    }
    .img-c{
      width: 20%;
      padding: 0.1rem;
     }
  }
  
 