.container-serv11{
    display: flex;
    background-image: url('../../assets/4718FAC7-2078-43E7-9449-C24AC80C5C04.JPG');
    background-size: cover;
    background-position: 60%; /* Adjust background position */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
 }
 .container-serv1{
    display: flex;
 }
 
 .img-serv1{
     width: 20rem;
 }
 .icons{
    margin: 3rem;
}
 
 @media (max-width: 786px) {
     .container-serv1{
         flex-wrap: wrap;
      }
      .img-serv1{
         width: 100%;
     }
     .icons{
        margin: 1rem;
    }
 }