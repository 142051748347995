.container-join{
    display: flex;
    justify-content: center;
     
 }
 
 .img-join{
     width: 20rem;
 }

 .lil-imgs{
    display: flex;
    justify-content: start;

 }
 
 @media (max-width: 786px) {
     .container-join{
         flex-wrap: wrap;
      }
      .img-join{
         width: 100%;
     }
 }