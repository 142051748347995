.container-serv2{
    display: flex;
 }
 .container-serv22{
    display: flex;
    background-image: url('../../assets/272C6714-47B8-4946-BDF0-4197B9CE83BD.JPG');
    background-size: cover;
    background-position: 100%; /* Adjust background position */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
 }
 
 .img-serv2{
     width: 20rem;
 }
 .img-serv22{
    padding: 0 10rem 0 10rem;
    width:100%;
    border-radius: 50%;
}
 @media (max-width: 786px) {
     .container-serv2{
         flex-wrap: wrap;
      }
      .img-serv22{
        padding: 0;
        width:100%;
        border-radius: 0;
     }
     .img-serv2{
        width: 100%;
    }
 }