@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    box-sizing: border-box;
    margin: 0;
  }
  .bg {
    overflow: hidden;
    white-space: wrap;
    word-break: break-word;
    background-color: #b5c0bc;
  }

  .text1{
    top: 50%;
    padding: 2rem;
    text-align: center;
    color: rgb(19, 90, 94);
    font-family: "Poppins", serif;
    font-size: 3.5rem; /* Adjust the font size */
    font-weight: 400; /* Adjust the font weight */
  }
  .text2 {
    padding: 2rem;
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
  }
  .text2 span {
    color: rgb(19, 90, 94);
  }

  .container {
    /* margin: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 20px; 
  }

  .gallery-container {
    border-radius: 5px;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .large-image {
    background-color: rgb(248, 248, 248);
    height: 500px;
    width: 600px; /* Adjust according to your preference */
    margin-bottom: 1px;
    border-radius: 5%;
  }

  .specs-images{
    width: 70px;
  }
  .specs .text2 span{
    font-size: 1.1rem;
  }
  
  .thumbnail-container {
    display: flex;
    justify-content: center;
  }
  
  .thumbnail {
    background-color: rgb(248, 248, 248);
    border-radius: 5%;
    width: 200px; /* Adjust according to your preference */
    height: auto;
    margin: 1%;
    cursor: pointer;
  }

  .hr-with-shadow {
    border: none;
    height: 1px; /* Adjust the height of the horizontal rule as needed */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3)); /* Create a linear gradient for shadow effect */
}

  
@media (max-width: 786px) {
    .thumbnail {
      width: 60px;
      }
      .large-image {
        height: 300px;
        width: 320px;
        /* height: ; */
      }
      .text1{
        font-size: 2.5rem;
      }
      .text2 {
        font-size: 1.2rem;
      }
      .specs-images{
        width: 70px;
        margin-left: 10px;
        margin-right: 35px;
      }

}