@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.header {
    position: fixed; /* Make the header stick to the top */
    top: -2%; /* Position it at the top of the viewport */
    z-index: 2; /* Ensure it's above other content */
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.nav-menu {
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding-top: 6rem; /* Adjust as needed based on your header height */
    z-index: 2;
    position: absolute;
    padding: 2rem;
    list-style: none;
    display: flex;
    gap: 2rem;
    color: rgb(0, 0, 0);
}

.nav-btn {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    text-decoration: none;
    color: rgb(0, 0, 0);
}

.nav-btn:hover {
    color: rgb(13, 103, 100);
}

/* Media query for small screens */
@media (max-width: 768px) {
    .header {
        position: fixed; /* Make the header stick to the top */
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.938);
        z-index: 2;
        padding: 2rem; /* Remove padding */
        border-radius: 5px;
    }

     .nav-menu.open {
        display: flex;
        /* animation: slideIn 1s ease-in;  */
    }
/*
    @keyframes slideIn {
        0% {transform: translateY(0%);}
        100% {transform: translateY(100%);}
    } */

    .nav-btn {
        margin: 0px;
        padding: 10px;
    }
    .menu-toggle {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        background: none;
        border: none;
    }

    .menu-toggle span {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px 0;
        transition: 0.4s;
    }

    .menu-toggle {
        z-index: 2;
        padding: 2rem;
        display: block;
        position: absolute;
        top: 5px;
        left: 0%;
        cursor: pointer;
        background: none;
        border: none;
    }

    .menu-toggle span {
        display: block;
        width: 25px;
        height: 3px;
        background-color: rgb(0, 0, 0);
        margin: 5px 0;
        transition: 0.4s;
    }

    .menu-toggle.open span:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .menu-toggle.open span:nth-child(2) {
        opacity: 0;
    }

    .menu-toggle.open span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

/* Media query for larger screens */
@media (min-width: 769px) {
    .menu-toggle {
        display: none; /* Hide the toggle button on larger screens */
    }

}
