@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    box-sizing: border-box;
    margin: 0;
  }
  .hero {
    overflow: hidden;
    white-space: wrap;
    word-break: break-word;
  }

.hero {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-color: rgb(255, 255, 255);
    color: azure;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.7rem;
    display: flex; /* Ensure flex layout for proper alignment */
    flex-wrap: wrap;
}

.right {
    flex-grow: 1; /* Use flex to fill available space */
    background-image: url('../../../public/SE_Fun_Ride_Bridge_Ride.jpg.webp');
    background-size: cover;
    background-position: 60%; /* Adjust background position */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
    /* filter: blur(50px); Add blur effect to the image */
}

.left {
    flex-grow: 1;
    content: ''; /* Create a pseudo-element to act as the overlay layer */
    position: absolute; /* Position the overlay layer absolutely */
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.846); /* Semi-transparent black color */
    z-index: 1; /* Ensure the overlay layer is above the image */
}


    .logo { 
        width: 80%;
        position: absolute; 
        left: 10%; 
        top: 25%; 
        z-index: +1; 
    } 

.right .content{
    position: absolute; 
        top: 60%; 
        right: 2%;
        font-family: "Poppins";
        flex-direction: column;
        border-radius: 10% 30% 50% 70%;
        background-color: #0000004a;
        /* background-color: #05161a; */
        box-shadow: inset;
        width: 40%;
    }
    .text1-h{
        text-align: center;
        color: rgb(255, 255, 255);
        font-family: "Poppins", serif;
        font-size: 3.5rem; /* Adjust the font size */
        font-weight: 400; /* Adjust the font weight */
    }

    .start-button{
       display: none;
    }


    button{
        color: #05161a;
        padding: 0.7rem;
        font-family: "Poppins", sans-serif;
        border: none;
        background-color: #0f989c;
        width: 12rem;
        font-size: 1.5rem;
        border-radius: 20px;
    }

    .wheel { 
        width: 10%;
        z-index: 1;
        right: 40%;
        top: 87%;
        position: absolute;
    }
    .wheel { 
        width: 20%;
        animation : rotate 3s infinite; 
    }
    
    @keyframes rotate{
        0% {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    } 
    
   
@media (max-width: 786px) {
    .hero {
        flex-direction: column; /* Change to column layout for smaller screens */
    }
    .left{
        width: 100%;
    }
    .right .content{
        display: none; 
        /* z-index: 2;
        top: 60%;
        right: 4%;
        width: 90%;
        background-color: #05161aec; */

        
    } 
     .wheel { 
        width: 50%;
        z-index: 1;
        right:25%;
        top: 49%;
        position: absolute;
    }

}
@media (min-width: 768px) {
    .wheel {
      display: none; /* Hide the wheel on large screens */
    }
}