.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: rgb(10, 21, 35);
}
.logo-f img{
    width: 10rem;
}
.social-links{
    display: flex;
    gap: 2rem;
}
/* Target img elements inside .social-links */
.social-links img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    
}

/* Target list items inside .menu */
.menu .nav-btn{
    font-size: 2rem;
    gap: 2rem;
    padding: 1rem;
    color: rgb(255, 255, 255);
}

/* Target list items inside .menu on hover */
.menu .nav-btn:hover {
    color: rgb(13, 103, 100);
}
.social-links img:hover {
    transform: scale(1.3); /* Increase scale to 1.3 times */
}


/* Adjust styles for .menu at max-width: 768px */
@media (max-width: 768px) {
    .menu .nav-btn{
        padding: 1rem;
    }
    .menu{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}
